import request from '@/utils/request'

// 批量添加更新分店价格方案  新增
export function saveOrUpdateMovePriceCase (data) {
  return request({
    url: '/api/system/shop/base/info/saveOrUpdateMovePriceCase',
    method: 'post',
    data
  })
}
//分店价格方案  详情
export function getMovePriceCaseDetail (movePriceCaseId) {
  return request({
    url:
      '/api/system/shop/base/info/getMovePriceCaseDetail?movePriceCaseId=' +
      movePriceCaseId,
    method: 'get'
  })
}
